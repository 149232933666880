@tailwind base;

@layer base {

  @font-face {
    font-family: 'Heading Now';
    src: url('assets/fonts/HeadingNow-74Regular.eot');
    src: local('Heading Now W05 74 Regular'), local('HeadingNow-74Regular'),
         url('assets/fonts/HeadingNow-74Regular.eot?#iefix') format('embedded-opentype'),
         url('assets/fonts/HeadingNow-74Regular.woff') format('woff'),
         url('assets/fonts/HeadingNow-74Regular.ttf') format('truetype'),
         url('assets/fonts/HeadingNow-74Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Heading Now';
    src: url('assets/fonts/HeadingNow-73Book.eot');
    src: local('Heading Now W05 73 Book'), local('HeadingNow-73Book'),
         url('assets/fonts/HeadingNow-73Book.eot?#iefix') format('embedded-opentype'),
         url('assets/fonts/HeadingNow-73Book.woff') format('woff'),
         url('assets/fonts/HeadingNow-73Book.ttf') format('truetype'),
         url('assets/fonts/HeadingNow-73Book.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Heading Now';
    src: url('assets/fonts/HeadingNow-72Light.eot');
    src: local('Heading Now W05 72 Light'), local('HeadingNow-72Light'),
         url('assets/fonts/HeadingNow-72Light.eot?#iefix') format('embedded-opentype'),
         url('assets/fonts/HeadingNow-72Light.woff') format('woff'),
         url('assets/fonts/HeadingNow-72Light.ttf') format('truetype'),
         url('assets/fonts/HeadingNow-72Light.otf') format('opentype');
    font-weight: 200;
    font-style: normal;
  }

  @media (max-width: 1279.99px) {
    html {
      height: -webkit-fill-available;
    }
    body {
      min-height: 100vh;
      min-height: -webkit-fill-available;
    }
  }

  section {
    @apply relative z-10;
  }

  ::-webkit-scrollbar {
    width: 7px
  }

  ::-webkit-scrollbar-track {
    background-color: rgb(8, 11, 15)
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 1px solid #0f1116;
    background-color: #e97f1c
  }

}


@tailwind components;

@layer components {

  .text {
    @apply text-vw-xl font-light uppercase;
  }
  .text-mobile {
    @apply text-vw-sm font-light uppercase;
  }

  .main-link {
    @apply font-light uppercase;
  }

  .field {
    @apply border-0 rounded-none;
  }
  .checkbox {
    
  }

  @variants responsive, hover {
    .main-underline::after {
      content: '';
      @apply bg-orange absolute inset-x-0 bottom-0;
      height: 0.1vw;
    }
    .no-underline::after {
      @apply bg-transparent
    }
  }

  .override-opacity {
    opacity: 1 !important;
  }

  .richtext {
    @apply xl:text-vw-xl text-vw-base;
  }
  .richtext {
    @apply leading-normal;
  }
  .richtext * {
    @apply align-text-top xl:mb-vw-4 mb-vw-20;
  }
  .richtext *:empty {
    @apply h-px;
  }
  .richtext > h2 {
    @apply xl:text-vw-3xl text-vw-xl font-bold;
  }
  .richtext > h3 {
    @apply xl:text-vw-2xl text-vw-lg font-bold;
  }
  .richtext ol, .richtext ul {
    @apply xl:pl-vw-5 pl-vw-16;
  }
  .richtext ol > p:first-child,
  .richtext ul > p:first-child,
  .richtext ol > b:first-child,
  .richtext ul > b:first-child {
    @apply xl:pl-vw-5 pl-vw-16;
  }
  .richtext ol {
    @apply list-decimal;
  }
  .richtext ul {
    @apply list-disc;
  }
  .richtext b {
    @apply font-bold;
  }

  .w-vw-px {
    min-width: 1px;
  }

}


@tailwind utilities;

@layer utilities {

  @media (max-width: 639.99px) {
    @variants responsive {
      .text-vw-xs {
        font-size: 2.9vw;
        line-height: 3.45vw;
      }
      .text-vw-sm {
        font-size: 3.37vw;
        line-height: 4.55vw;
      }
      .text-vw-base {
        font-size: 3.87vw;
        line-height: 4.7vw;
      }
      .text-vw-lg {
        font-size: 4.57vw;
        line-height: 5.75vw;
      }
      .text-vw-xl {
        font-size: 4.83vw;
        line-height: 5.9vw;
      }
      .text-vw-2xl {
        font-size: 6.1vw;
        line-height: 1;
      }
      .text-vw-3xl {
        font-size: 7.25vw;
        line-height: 1;
      }
      .text-vw-4xl {
        font-size: 8.66vw;
        line-height: 1;
      }
      .text-vw-5xl {
        font-size: 11.6vw;
        line-height: 1;
      }
      .text-vw-6xl {
        font-size: 14.5vw;
        line-height: 1;
      }
      .text-vw-7xl {
        font-size: 17.4vw;
        line-height: 1;
      }
    }

    .main-underline::after {
      bottom: -0.1vw;
      height: 0.4vw;
    }
  }

}